import {
  getCurrentInstance,
} from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientMessage() {
  const instance = getCurrentInstance().proxy

  const showMessage = async ({ text, type = 'success' }) => {
    if (type === 'success') {
      instance.$toast({
        component: ToastificationContent,
        props: {
          title: 'Удалено!',
          icon: 'BellIcon',
          text,
          variant: 'success',
        },
      })
    } else if (type === 'error') {
      instance.$swal({
        icon: 'error',
        title: 'Ошибка!',
        text,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      })
    }
  }

  return {
    showMessage,
  }
}
