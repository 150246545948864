<template>
  <h-layout class="page-content-page">
    <template #header>
      <header-partial>
        <template #right>
          <b-button
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            :to="{ name: 'sites.store.pages.edit', params: { id: id } }"
          >
            <feather-icon
              icon="SettingsIcon"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            class="ml-1"
            @click="savePageBlocks"
          >
            Сохранить
          </b-button>
        </template>
      </header-partial>
    </template>
    <div v-if="getPageError">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Ошибка при получении данных страницы
        </h4>
        <div class="alert-body">
          Не найдена страница с этим идентификатором.
        </div>
      </b-alert>
    </div>
    <b-overlay
      v-else
      :show="loading"
      spinner-variant="primary"
    >
      <app-breadcrumb
        :page-title="pageTitle"
        :breadcrumb="breadcrumb"
      />

      <div class="page-content-page__blocks">
        <b-button
          class="d-block mx-auto"
          variant="flat-primary"
          size="sm"
          @click="showAddBlockSidebar(0)"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Блок</span>
        </b-button>
        <div
          v-for="(section, section_index) in sections"
          :key="'s' + section_index"
          class=""
        >
          <component
            :is="componentMap[section.type]"
            class="page-content-page__block"
            :section="section"
          />
          <b-button
            class="d-block mx-auto"
            variant="flat-primary"
            size="sm"
            @click="showAddBlockSidebar(section_index + 1)"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Блок</span>
          </b-button>
        </div>

      </div>

    </b-overlay>

    <add-block-sidebar
      :is-active.sync="isShowAddBlockSidebar"
      @add-block="addBlock"
    />
  </h-layout>
</template>
<script setup>
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import HeaderPartial from '@/components/partials/HeaderPartial.vue'
import HLayout from '@/components/sub-layout/HLayout.vue'
import { getPage as getPageApi, updatePage as updatePageApi } from '@/services/main-api/websites/stores/pages'
import { getWebsite as getWebsiteApi } from '@/services/main-api/websites/stores/websites'
import {
  ref, defineProps, onMounted, computed, provide,
} from 'vue'
import get from 'lodash/get'
import AddBlockSidebar from '@/modules/sites-section/site-store-section/pages/components/AddBlockSidebar.vue'
import { componentMap } from '@/modules/sites-section/site-store-section/pages/composables/sectionList'
import useClientMessage from '@/composables/useClientMessage'
import useBlocksStore from '../store/useBlocksStore'

const props = defineProps({
  id: {
    type: [String, Number],
    default: null,
  },
})

const loading = ref(false)

const page = ref(null)
const getPageError = ref(false)

// BEGIN Site
const site = ref(null)

const { showMessage } = useClientMessage()

const getSite = async () => {
  try {
    site.value = (await getWebsiteApi({
      id: page.value.website_id,
    })).data.data
  } catch (e) {
    site.value = null
  }
}
// END Site

// BEGIN Sections
const { items: sections, addItem: addSection, setItems: setSectionStore } = useBlocksStore()

const getSections = () => {
  setSectionStore(get(page.value, 'dev_data.blocks', []))
}
// END Sections

const getPage = async () => {
  try {
    const res = await getPageApi({ id: props.id })
    page.value = res.data.data
    await getSite()
    getSections()
  } catch (e) {
    page.value = {}
    getPageError.value = true
  }
}

const savePageBlocks = async () => {
  loading.value = true

  const devData = get(page.value, 'dev_data', {})
  devData.blocks = sections.value

  try {
    await updatePageApi({
      id: page.value.id,
      dev_data: devData,
    })
    await showMessage({
      text: 'Изменения сохранены',
      type: 'success',
    })
  } catch (e) {
    await showMessage({
      text: 'Что-то пошло не так, попробуйте позже',
      type: 'error',
    })
  }

  loading.value = false
}

const getData = async () => {
  loading.value = true
  await getPage()
  loading.value = false
}

// BEGIN breadcrumb
const pageTitle = computed(() => {
  if (page.value) {
    return `Страница: ${get(page.value, 'name', '')}`
  }
  return 'Страница'
})
const breadcrumb = computed(() => {
  const bItems = [
    {
      text: 'Сайты',
      to: {
        name: 'sites',
      },
    },
    {
      text: pageTitle.value,
      active: true,
    },
  ]

  if (site.value) {
    // add 2 items
    bItems.splice(1, 0, {
      text: get(site.value, 'name', 'Сайт'),
      to: {
        name: 'sites.store.show',
        params: {
          id: get(site.value, 'id', null),
        },
      },
    })
  }

  return bItems
})
// END breadcrumb

const isShowAddBlockSidebar = ref(false)
const indexAddBlock = ref(null)
const showAddBlockSidebar = btnIndex => {
  isShowAddBlockSidebar.value = true
  indexAddBlock.value = btnIndex
  console.log(btnIndex)
}

const addBlock = block => {
  addSection(block, indexAddBlock.value)
}

onMounted(async () => {
  await getData()
})
</script>
