import { ref } from 'vue'

const items = ref([])

const removeItemByIndex = index => {
  items.value.splice(index, 1)
}

const removeItemById = id => {
  const index = items.value.findIndex(item => item.id === id)
  if (index > -1) {
    items.value.splice(index, 1)
  }
}

const addItem = (item, index) => {
  if (index) {
    items.value.splice(index, 0, item)
  } else {
    items.value.push(item)
  }
}

const moveItem = (id, direction) => {
  let index = items.value.findIndex(item => item.id === id)
  if (index > -1) {
    const item = items.value[index]
    items.value.splice(index, 1)

    if (direction === 'up') {
      index -= 1
      if (index < 0) {
        index = 0
      }
    }
    if (direction === 'down') {
      index += 1
      if (index > items.value.length) {
        index = items.value.length
        console.log(index)
      }
    }
    items.value.splice(index, 0, item)
  }
}

const setItems = newItems => {
  items.value = newItems
}

const clearItems = () => {
  items.value = []
}

const setItem = (id, item) => {
  const index = items.value.findIndex(i => i.id === id)
  if (index > -1) {
    items.value[index] = item
  }
}

const setActiveItem = (id, value) => {
  const index = items.value.findIndex(i => i.id === id)
  if (index > -1) {
    items.value[index].active = value
  }
}

const setSettingsItem = (id, settings) => {
  console.log('setSettingsItem')
  console.log(id, settings)

  const index = items.value.findIndex(i => i.id === id)
  if (index > -1) {
    items.value[index].settings = settings
  }
}

export default function useBlocksStore() {
  return {
    items,
    removeItemByIndex,
    removeItemById,
    addItem,
    setItems,
    clearItems,
    moveItem,
    setItem,
    setActiveItem,
    setSettingsItem,
  }
}
