const TextSection = () => import('@/modules/sites-section/site-store-section/pages/components/sections/TextSection.vue')
const MdSection = () => import('@/modules/sites-section/site-store-section/pages/components/sections/MdSection.vue')
const TitleSection = () => import('@/modules/sites-section/site-store-section/pages/components/sections/TitleSection.vue')

export const componentMap = {
  text: TextSection,
  md: MdSection,
  title: TitleSection,
}

export const categories = [
  {
    id: 1,
    title: 'Текстовые блоки',
  },
]

export const blocks = [
  {
    categoryId: 1,
    active: false,
    type: 'title',
    title: 'Заголовок',
    description: '',
    previewImage: 'https://placehold.co/600x400',
    settings: [
      {
        cols: 12,
        key: 'title',
        type: 'string',
        title: 'Заголовок',
        rules: '',
        description: '',
        value: 'Ваш заголовок',
      },
      {
        cols: 12,
        key: 'type',
        type: 'select',
        title: 'Тип',
        description: '',
        rules: 'required',
        value: 'h1',
        options: [
          {
            value: 'h1',
            label: 'h1',
          },
          {
            value: 'h2',
            label: 'h2',
          },
          {
            value: 'h3',
            label: 'h3',
          },
          {
            value: 'h4',
            label: 'h4',
          },
          {
            value: 'h5',
            label: 'h5',
          },
          {
            value: 'h6',
            label: 'h6',
          },
        ],
      },
    ],
  },
  {
    categoryId: 1,
    active: false,
    type: 'text',
    title: 'Текст',
    description: 'Обычный текстовый блок',
    previewImage: 'https://placehold.co/600x400',
    settings: [
      {
        key: 'title',
        cols: 12,
        type: 'string',
        title: 'Заголовок',
        description: '',
        value: 'Ваш заголовок',
      },
      {
        key: 'sub_title',
        cols: 12,
        type: 'string',
        title: 'Подзаголовок',
        description: '',
        value: 'Ваш подзаголовок',
      },
      {
        key: 'text',
        cols: 12,
        type: 'text',
        title: 'Текст',
        description: '',
        value: 'Какой то текст',
      },
    ],
  },
  {
    categoryId: 1,
    active: false,
    type: 'md',
    title: 'Markdown',
    description: 'Блок с markdown',
    previewImage: 'https://placehold.co/600x400',
    settings: [
      {
        key: 'md',
        cols: 12,
        type: 'md',
        title: 'Markdown',
        description: '',
        value: '## Ваш markdown',
      },
    ],
  },
]

export const categoriesWithBlocks = categories.map(category => ({
  ...category,
  items: blocks.filter(block => block.categoryId === category.id),
}))

export default {
  blocks,
  categories,
  categoriesWithBlocks,
  componentMap,
}
