<script setup>
import { defineProps, defineEmits } from 'vue'
import {
  BSidebar, BCard, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import { categoriesWithBlocks } from '../composables/sectionList'

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:is-active', 'add-block'])

function hiddenHandler() {
  // ...
}

const addBlock = block => {
  const newBlock = {
    ...block,
    id: Date.now(),
  }
  emit('add-block', newBlock)
  emit('update:is-active', false)
}
</script>

<template>
  <b-sidebar
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="hiddenHandler"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить блок
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div class="p-2 add-block-sidebar">
        <div
          v-for="category in categoriesWithBlocks"
          :key="'cabs' + category.id"
          class="add-block-sidebar__category"
        >
          <div class="add-block-sidebar__category__name h6 mb-1">
            {{ category.title }}
          </div>
          <div class="add-block-sidebar__category__items">

            <b-card
              v-for="item in category.items"
              :key="'cabs' + category.id + 'item' + item.type"
              :img-src="item.previewImage"
              :img-alt="item.title"
              img-top
              no-body
              class="add-block-sidebar__category__item mb-0"
              @click="addBlock(item)"
            >
              <b-card-body>
                <h5 class="mb-0 add-block-sidebar__category__item__name">
                  {{ item.title }}
                </h5>
              </b-card-body>
            </b-card>

          </div>
        </div>

      </div>
    </template>
  </b-sidebar>
</template>

<style scoped lang="scss">
.add-block-sidebar{
  &__category{
    margin-top: 30px;
    &:first-child{
      margin-top: 0;
    }
    &__name{

    }
    &__items{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
    &__item{
      width: 100%;
      cursor: pointer;
      &:hover{
        // transform: translateY(-3px);
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%), 0 0 0 1px var(--primary);
      }
      &:hover &__name{
        color: var(--primary) !important;
      }
      &__preview{

      }
      &__name{

      }
    }
  }
}
</style>
